import { styled } from "../stitches.config"

const Text = styled("span", {
    margin: 0,
    fontVariantNumeric: "tabular-nums",
    display: "block",
    variants: {
        size: {
            "label-01": {
                fontSize: "$1",
            },
            "label-02": {
                fontSize: "$2",
            },
            "body-01": {
                fontSize: "$2",
                lineHeight: "$4",
            },
            "body-02": {
                fontSize: "$3",
                lineHeight: "$6",
            },
            "body-03": {
                fontSize: "$4",
                lineHeight: "$7",
            },
            "heading-01": {
                fontSize: "$2",
                lineHeight: "$6",
                letterSpacing: "$8",
                fontWeight: "$bold",
            },
            "heading-02": {
                fontSize: "$3",
                lineHeight: "$6",
                letterSpacing: "$0",
                fontWeight: "$bold",
            },
            "heading-03": {
                fontSize: "$5",
                lineHeight: "$7",
                letterSpacing: "$0",
                fontWeight: "$normal",
            },
            "heading-04": {
                fontSize: "$7",
                lineHeight: "$9",
                letterSpacing: "$0",
                fontWeight: "$normal",
            },
            "heading-05": {
                fontSize: "$8",
                lineHeight: "2.5rem",
                letterSpacing: "$0",
                fontWeight: "$normal",
            },
            "heading-06": {
                fontSize: "$10",
                lineHeight: "3.125rem",
                letterSpacing: "$0",
                fontWeight: "$normal",
            },
            "heading-07": {
                fontSize: "$12",
                lineHeight: "4rem",
                letterSpacing: "$0",
                fontWeight: "$normal",
            },
        },
        color: {
            gray: {
                color: "$mauve11",
            },
        },
    },
})

export default Text

import * as React from "react"
import { Link as GatsbyLink } from "gatsby"
import { styled } from "../stitches.config"

export const linkStyle = {
    all: "unset",
    borderBottom: "solid 2px $realBlue",
    transition: "all 0.24s ease-in-out",
    cursor: "pointer",
    fontWeight: "$semibold",
    px: 1,
    "@hover": {
        "&:hover": {
            background: "$realBlue",
            color: "$white",
        },
    },
}

const Link = styled(GatsbyLink, linkStyle)

export default Link

module.exports = [{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-google-analytics-virtual-a45195fa54/0/cache/gatsby-plugin-google-analytics-npm-4.11.0-07f3deb030-874fe61972.zip/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"G-E5QL46DVEY","head":false,"anonymize":true,"respectDNT":false,"pageTransitionDelay":0,"defer":false,"enableWebVitalsTracking":true,"exclude":[]},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-mdx-virtual-ed9fdcb991/0/cache/gatsby-plugin-mdx-npm-3.11.1-5688d8044d-50d615e1a5.zip/node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyRemarkPlugins":[{"resolve":"/Users/robert/projects/rharink/robhar.com/packages/gatsby-wiki-links","id":"2ed2f03c-6018-5297-82ba-8bc94465a19c","name":"gatsby-wiki-links","version":"0.1.0","modulePath":"/Users/robert/projects/rharink/robhar.com/packages/gatsby-wiki-links/index.js","module":{},"pluginOptions":{"plugins":[]},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]},{"resolve":"/Users/robert/projects/rharink/robhar.com/.yarn/cache/gatsby-transformer-markdown-references-npm-0.1.8-12bb79cac2-5b4827f34a.zip/node_modules/gatsby-transformer-markdown-references","id":"a7e74a18-24eb-5adc-b724-6020c106f2c1","name":"gatsby-transformer-markdown-references","version":"0.1.8","modulePath":"/Users/robert/projects/rharink/robhar.com/.yarn/cache/gatsby-transformer-markdown-references-npm-0.1.8-12bb79cac2-5b4827f34a.zip/node_modules/gatsby-transformer-markdown-references/index.js","module":{"REGEX_FENCED_CODE_BLOCK":{}},"pluginOptions":{"plugins":[]},"nodeAPIs":["onCreateNode","createSchemaCustomization","setFieldsOnGraphQLNodeType","pluginOptionsSchema"],"browserAPIs":[],"ssrAPIs":[]},{"resolve":"/Users/robert/projects/rharink/robhar.com/.yarn/__virtual__/gatsby-remark-images-virtual-30a29ee581/0/cache/gatsby-remark-images-npm-6.11.1-c6ea0ad153-0788a87d8e.zip/node_modules/gatsby-remark-images","id":"32658888-e97e-563f-9132-b45b813fd0fb","name":"gatsby-remark-images","version":"6.11.1","modulePath":"/Users/robert/projects/rharink/robhar.com/.yarn/__virtual__/gatsby-remark-images-virtual-30a29ee581/0/cache/gatsby-remark-images-npm-6.11.1-c6ea0ad153-0788a87d8e.zip/node_modules/gatsby-remark-images/index.js","pluginOptions":{"plugins":[],"maxWidth":1200},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]}],"extensions":[".mdx"],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/Users/robert/projects/rharink/robhar.com","commonmark":false},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-remark-images-virtual-30a29ee581/0/cache/gatsby-remark-images-npm-6.11.1-c6ea0ad153-0788a87d8e.zip/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1200},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-image-virtual-6faa3c14f5/0/cache/gatsby-plugin-image-npm-2.11.1-d601040aa4-26b9675422.zip/node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    }]

import * as React from "react"
import { styled } from "../stitches.config"
import Box from "./Box"
import Text from "./Text"
import { GitHubLogoIcon, LinkedInLogoIcon } from "@radix-ui/react-icons"

import { github, linkedin } from "../util/socialLinks"
import A from "./A"

const StyledFooter = styled(Box, {
    display: "flex",
    gap: "$1",
})

const GhostLink = styled("a", {
    all: "unset",
    cursor: "pointer",
    "@hover": {
        "&:hover": {
            color: "$realBlue",
            background: "none",
        },
    },
})

export default function Footer() {
    return (
        <StyledFooter as="footer">
            <Text size="label-01" css={{ flexGrow: 1 }}>
                Copyright &copy; 2022 Robert den Harink
            </Text>
            <GhostLink target="_blank" href={github} aria-label="Github">
                <GitHubLogoIcon />
            </GhostLink>
            <GhostLink target="_blank" href={linkedin} aria-label="LinkedIn">
                <LinkedInLogoIcon />
            </GhostLink>
            <Box
                css={{
                    position: "absolute",
                    right: 10,
                    bottom: 10,
                    color: "transparent",
                }}
            >
                ⍼
            </Box>
        </StyledFooter>
    )
}

import type { GatsbyConfig } from "gatsby"

export const siteUrl = "https://robhar.com"

const config: GatsbyConfig = {
    siteMetadata: {
        title: `Robhar`,
        description: "Personal blog of Robert den Harink",
        siteUrl,
        social: {
            twitter: "@rauwekost",
            github: "rharink",
            linkedin: "robertdenharink",
        },
    },
    plugins: [
        {
            resolve: "gatsby-plugin-google-analytics",
            options: {
                trackingId: "G-E5QL46DVEY",
                // Defines where to place the tracking script - `true` in the head and `false` in the body
                head: false,
                // Setting this parameter is optional
                anonymize: true,
                // Setting this parameter is also optional
                respectDNT: false,
                // Delays sending pageview hits on route update (in milliseconds)
                pageTransitionDelay: 0,
                // Defers execution of google analytics script after page load
                defer: false,
                // Any additional optional fields
                // defaults to false
                enableWebVitalsTracking: true,
            },
        },
        {
            resolve: `gatsby-plugin-mdx`,
            options: {
                gatsbyRemarkPlugins: [
                    {
                        resolve: `gatsby-wiki-links`,
                    },
                    {
                        resolve: `gatsby-transformer-markdown-references`,
                    },
                    {
                        resolve: `gatsby-remark-images`,
                        options: {
                            maxWidth: 1200,
                        },
                    },
                ],
            },
        },
        {
            resolve: "gatsby-source-filesystem",
            options: {
                name: "content",
                path: "./content",
            },
        },
        {
            resolve: "gatsby-source-filesystem",
            options: {
                name: "pages",
                path: "./src/pages",
            },
        },
        "gatsby-plugin-cname",
        "gatsby-plugin-image",
        "gatsby-plugin-react-helmet",
        "gatsby-plugin-sitemap",
        "gatsby-plugin-sharp",
        "gatsby-transformer-sharp",
    ],
}

export default config

import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { styled } from "../stitches.config"
import Box from "./Box"
import Flex from "./Flex"
import Link from "./Link"
import Text from "./Text"
import Span from "./Span"

const StyledHeader = styled(Flex, {})

const MenuLink = styled(Link, {
    borderBottom: "none",
    "@hover": {
        "&:hover": {
            background: "none",
            color: "$realBlue",
        },
    },
})

export default function Header() {
    const data = useStaticQuery(graphql`
        {
            posts: allMdx(
                filter: {
                    frontmatter: {
                        type: { eq: "post" }
                        published: { eq: true }
                    }
                }
            ) {
                edges {
                    node {
                        id
                    }
                }
            }
            notes: allMdx(
                filter: {
                    frontmatter: {
                        type: { eq: "note" }
                        private: { eq: false }
                    }
                }
            ) {
                edges {
                    node {
                        id
                    }
                }
            }
        }
    `)

    return (
        <StyledHeader align="center" gap="2">
            <Box css={{ flexGrow: 1 }}>
                <Text size="heading-02" css={{ fontWeight: "$bold" }}>
                    <MenuLink
                        to="/"
                        css={{
                            display: "flex",
                            alignItems: "center",
                            gap: "$2",
                        }}
                    >
                        <Span css={{ fontSize: "2rem" }}>⍨</Span>
                        <Span>robhar</Span>
                    </MenuLink>
                </Text>
            </Box>
            {data.posts.edges.length > 0 && (
                <Text size="heading-02">
                    <MenuLink to="/posts">Blog</MenuLink>
                </Text>
            )}
            {data.notes.edges.length > 0 && (
                <Text size="heading-02">
                    <MenuLink to="/notes">Notes</MenuLink>
                </Text>
            )}
        </StyledHeader>
    )
}

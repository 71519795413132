import * as React from "react"
import { globalCss, styled } from "../stitches.config"
import { reset } from "../reset"
import Header from "./Header"
import Footer from "./Footer"
import Flex from "./Flex"
import Helmet from "react-helmet"
import SEO from "./SEO"
import { linkStyle } from "./Link"

globalCss({
    ...reset,
    "body, html": {
        margin: 0,
        fontFamily: "$sans",
        background: "$mauve2",
        color: "$mauve12",
    },
    "::selection, -moz-selection": {
        color: "$white",
        background: "$realBlue",
    },
    a: linkStyle,
    pre: {
        fontFamily: "$mono",
        fontSize: "$2",
        lineHeight: "$5",
        borderRadius: "$1",
        whiteSpace: "pre-wrap",
        wordWrap: "break-word",
    },
    code: {
        fontFamily: "$mono",
        fontSize: "1rem",
        whiteSpace: "pre-wrap",
        wordWrap: "break-word",
        background: "$mauve11",
        color: "$mauve4",
        px: "$1",
        py: 2,
        borderRadius: "$1",
    },
})()

const Container = styled(Flex, {
    width: "$full",
    px: "$4",
    py: "$4",
    "@bp2": {
        width: 760,
        mx: "auto",
    },
})

export interface LayoutProps {
    children: any
}

export default function Layout({ children }: LayoutProps) {
    return (
        <Container direction="column" gap="4">
            <Header />
            {children}
            <Footer />
        </Container>
    )
}

import { createStitches } from "@stitches/react"
import type * as Stitches from "@stitches/react"
import {
    red,
    redA,
    blue,
    blueA,
    indigo,
    indigoA,
    teal,
    tealA,
    mauve,
    mauveA,
} from "@radix-ui/colors"

export type { VariantProps } from "@stitches/react"

export type CSS = Stitches.CSS<typeof config>

export const {
    styled,
    css,
    globalCss,
    keyframes,
    getCssText,
    theme,
    createTheme,
    config,
} = createStitches({
    theme: {
        colors: {
            ...red,
            ...redA,
            ...blue,
            ...blueA,
            ...indigo,
            ...indigoA,
            ...teal,
            ...tealA,
            ...mauve,
            ...mauveA,
            white: "#fff",
            realBlue: "#0000FF",
        },
        fonts: {
            sans: "Untitled, Helvetica Neue, -apple-system, system-ui, sans-serif",
            mono: "Söhne Mono, menlo, monospace",
        },
        fontSizes: {
            1: "0.75rem", // 12px
            2: "0.875rem", // 14px
            3: "1rem", // 16px
            4: "1.125rem", // 18px
            5: "1.25rem", // 20px
            6: "1.5rem", // 24px
            7: "1.75rem", // 28px
            8: "2rem", // 32px
            9: "2.25rem", // 36px
            10: "2.625rem", // 42px
            11: "3rem", // 48px
            12: "3.375rem", // 54px
            13: "3.75rem", // 60px
            14: "4.25rem", // 68px
            15: "4.75rem", // 76px
            16: "5.25rem", // 84px
            17: "5.75rem", // 92px
        },
        letterSpacing: {
            0: "0px", //0
            1: "0.75rem", // 12px
            2: "0.875rem", // 14px
            3: "1rem", // 16px
            4: "1.125rem", // 18px
            5: "1.25rem", // 20px
            6: "1.5rem", // 24px
            7: "1.75rem", // 28px
            8: "2rem", // 32px
            9: "2.25rem", // 36px
            10: "2.625rem", // 42px
            11: "3rem", // 48px
            12: "3.375rem", // 54px
            13: "3.75rem", // 60px
            14: "4.25rem", // 68px
            15: "4.75rem", // 76px
            16: "5.25rem", // 84px
            17: "5.75rem", // 92px
        },
        lineHeights: {
            1: "0.75rem", // 12px
            2: "0.875rem", // 14px
            3: "1rem", // 16px
            4: "1.125rem", // 18px
            5: "1.25rem", // 20px
            6: "1.5rem", // 24px
            7: "1.75rem", // 28px
            8: "2rem", // 32px
            9: "2.25rem", // 36px
            10: "2.625rem", // 42px
            11: "3rem", // 48px
            12: "3.375rem", // 54px
            13: "3.75rem", // 60px
            14: "4.25rem", // 68px
            15: "4.75rem", // 76px
            16: "5.25rem", // 84px
            17: "5.75rem", // 92px
        },
        space: {
            1: "4px",
            2: "8px",
            3: "16px",
            4: "32px",
            5: "64px",
            6: "128px",
            7: "256px",
            8: "512px",
        },
        sizes: {
            1: "4px",
            2: "8px",
            3: "16px",
            4: "32px",
            5: "64px",
            6: "128px",
            7: "256px",
            8: "512px",
            9: "1024px",
            half: "50%",
            full: "100%",
        },
        radii: {
            "1": "4px",
            "2": "8px",
            "3": "16px",
            "4": "32px",
            "5": "64px",
            round: "50%",
            pill: "9999px",
        },
        zIndices: {
            1: "100",
            2: "200",
            3: "300",
            4: "400",
            max: "999",
        },
        fontWeights: {
            standard: 400,
            semibold: 500,
            bold: 600,
            extrabold: 800,
        },
    },
    media: {
        bp1: "(min-width: 520px)",
        bp2: "(min-width: 760px)",
        bp3: "(min-width: 1200px)",
        bp4: "(min-width: 1800px)",
        motion: "(prefers-reduced-motion)",
        hover: "(any-hover: hover)",
        dark: "(prefers-color-scheme: dark)",
        light: "(prefers-color-scheme: light)",
    },
    utils: {
        p: (value: Stitches.PropertyValue<"padding">) => ({
            padding: value,
        }),
        pt: (value: Stitches.PropertyValue<"paddingTop">) => ({
            paddingTop: value,
        }),
        pr: (value: Stitches.PropertyValue<"paddingRight">) => ({
            paddingRight: value,
        }),
        pb: (value: Stitches.PropertyValue<"paddingBottom">) => ({
            paddingBottom: value,
        }),
        pl: (value: Stitches.PropertyValue<"paddingLeft">) => ({
            paddingLeft: value,
        }),
        px: (value: Stitches.PropertyValue<"paddingLeft">) => ({
            paddingLeft: value,
            paddingRight: value,
        }),
        py: (value: Stitches.PropertyValue<"paddingTop">) => ({
            paddingTop: value,
            paddingBottom: value,
        }),

        m: (value: Stitches.PropertyValue<"margin">) => ({
            margin: value,
        }),
        mt: (value: Stitches.PropertyValue<"marginTop">) => ({
            marginTop: value,
        }),
        mr: (value: Stitches.PropertyValue<"marginRight">) => ({
            marginRight: value,
        }),
        mb: (value: Stitches.PropertyValue<"marginBottom">) => ({
            marginBottom: value,
        }),
        ml: (value: Stitches.PropertyValue<"marginLeft">) => ({
            marginLeft: value,
        }),
        mx: (value: Stitches.PropertyValue<"marginLeft">) => ({
            marginLeft: value,
            marginRight: value,
        }),
        my: (value: Stitches.PropertyValue<"marginTop">) => ({
            marginTop: value,
            marginBottom: value,
        }),

        ta: (value: Stitches.PropertyValue<"textAlign">) => ({
            textAlign: value,
        }),

        fd: (value: Stitches.PropertyValue<"flexDirection">) => ({
            flexDirection: value,
        }),
        fw: (value: Stitches.PropertyValue<"flexWrap">) => ({
            flexWrap: value,
        }),

        ai: (value: Stitches.PropertyValue<"alignItems">) => ({
            alignItems: value,
        }),
        ac: (value: Stitches.PropertyValue<"alignContent">) => ({
            alignContent: value,
        }),
        jc: (value: Stitches.PropertyValue<"justifyContent">) => ({
            justifyContent: value,
        }),
        as: (value: Stitches.PropertyValue<"alignSelf">) => ({
            alignSelf: value,
        }),
        fg: (value: Stitches.PropertyValue<"flexGrow">) => ({
            flexGrow: value,
        }),
        fs: (value: Stitches.PropertyValue<"flexShrink">) => ({
            flexShrink: value,
        }),
        fb: (value: Stitches.PropertyValue<"flexBasis">) => ({
            flexBasis: value,
        }),

        bc: (value: Stitches.PropertyValue<"backgroundColor">) => ({
            backgroundColor: value,
        }),

        br: (value: Stitches.PropertyValue<"borderRadius">) => ({
            borderRadius: value,
        }),
        btrr: (value: Stitches.PropertyValue<"borderTopRightRadius">) => ({
            borderTopRightRadius: value,
        }),
        bbrr: (value: Stitches.PropertyValue<"borderBottomRightRadius">) => ({
            borderBottomRightRadius: value,
        }),
        bblr: (value: Stitches.PropertyValue<"borderBottomLeftRadius">) => ({
            borderBottomLeftRadius: value,
        }),
        btlr: (value: Stitches.PropertyValue<"borderTopLeftRadius">) => ({
            borderTopLeftRadius: value,
        }),

        bs: (value: Stitches.PropertyValue<"boxShadow">) => ({
            boxShadow: value,
        }),

        lh: (value: Stitches.PropertyValue<"lineHeight">) => ({
            lineHeight: value,
        }),

        ox: (value: Stitches.PropertyValue<"overflowX">) => ({
            overflowX: value,
        }),
        oy: (value: Stitches.PropertyValue<"overflowY">) => ({
            overflowY: value,
        }),

        pe: (value: Stitches.PropertyValue<"pointerEvents">) => ({
            pointerEvents: value,
        }),
        us: (value: Stitches.PropertyValue<"userSelect">) => ({
            WebkitUserSelect: value,
            userSelect: value,
        }),

        userSelect: (value: Stitches.PropertyValue<"userSelect">) => ({
            WebkitUserSelect: value,
            userSelect: value,
        }),

        size: (value: Stitches.PropertyValue<"width">) => ({
            width: value,
            height: value,
        }),

        appearance: (value: Stitches.PropertyValue<"appearance">) => ({
            WebkitAppearance: value,
            appearance: value,
        }),
        backgroundClip: (value: Stitches.PropertyValue<"backgroundClip">) => ({
            WebkitBackgroundClip: value,
            backgroundClip: value,
        }),
        linearGradient: (value: Stitches.PropertyValue<"backgroundImage">) => ({
            backgroundImage: `linear-gradient(${value})`,
        }),
    },
})

import { styled } from "../stitches.config"
import Box from "./Box"

export default styled(Box, {
    display: "flex",

    variants: {
        centered: {
            true: {
                alignItems: "center",
                justifyContent: "center",
            },
        },
        direction: {
            row: {
                flexDirection: "row",
            },
            column: {
                flexDirection: "column",
            },
            rowReverse: {
                flexDirection: "row-reverse",
            },
            columnReverse: {
                flexDirection: "column-reverse",
            },
        },
        grow: {
            "1": {
                flexGrow: 1,
            },
        },
        align: {
            start: {
                alignItems: "flex-start",
            },
            center: {
                alignItems: "center",
            },
            end: {
                alignItems: "flex-end",
            },
            stretch: {
                alignItems: "stretch",
            },
            baseline: {
                alignItems: "baseline",
            },
        },
        justify: {
            start: {
                justifyContent: "flex-start",
            },
            center: {
                justifyContent: "center",
            },
            end: {
                justifyContent: "flex-end",
            },
            between: {
                justifyContent: "space-between",
            },
        },
        wrap: {
            noWrap: {
                flexWrap: "nowrap",
            },
            wrap: {
                flexWrap: "wrap",
            },
            wrapReverse: {
                flexWrap: "wrap-reverse",
            },
        },
        gap: {
            1: {
                gap: "$1",
            },
            2: {
                gap: "$2",
            },
            3: {
                gap: "$3",
            },
            4: {
                gap: "$4",
            },
            5: {
                gap: "$5",
            },
            6: {
                gap: "$6",
            },
            7: {
                gap: "$7",
            },
            8: {
                gap: "$8",
            },
            9: {
                gap: "$9",
            },
        },
    },
    defaultVariants: {
        direction: "row",
        align: "stretch",
        justify: "start",
        wrap: "noWrap",
    },
})
